/* Styles reset */
html,
body,
ul,
li,
ol,
nav,
input,
div {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 1;
    font-weight: 400;
    list-style-type: none;
    box-sizing: border-box;
}
*:after, *:before {
    content: '';
    outline: 0 !important;
    position: absolute;
    box-sizing: inherit;
    text-decoration: inherit;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
input {
    border: none;
    outline: none;
    box-sizing: border-box;
}
button {
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
}
button::-moz-focus-inner {
    border: 0;
}
a {
    outline: none;
    text-decoration: none;
    border: none;
    color: inherit;
    &:link,
    &:visited,
    &:hover,
    &:active{
      color: inherit;
    }
}
b { font-weight: 600; }
p {
    line-height: 1.5;
    margin-block-start: 0em !important;
}
html {
    min-width: 320px;
}
body {
    background: #FFF;
}
h1,h2,h3,h4{
    font-weight: 400;
    margin: 0;
    line-height: 1.5;
    color: #202020;
    display: inline-block;
}
h1 { font-size: 1.5rem; font-weight: 400; }
h2 { font-size: 16px; }
h3 { font-size: 1.1rem; }
h3 { font-size: 18px; font-weight: 600; }
hr { border: 0; height: 1px; background: #333; background-image: linear-gradient(to right, #ccc, #333, #ccc) }
