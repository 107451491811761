/* Global styles variables configuration */
$app-width: 1920px;
$font-family: 'Nunito Sans', sans-serif;
$break-small: 1200px;
$break-medium: 600px;
$background-color: #ffffff;
$font-size-small: 14px;
$font-size-large: 15px;
$blend-zindex: 1020;
$border-color: #EFEFEF;
$content-container-margins-desktop: 3rem;
$content-container-margins-mobile: calc($content-container-margins-desktop / 2);
$content-header-padding: 24px;
$small-padding: 2rem;
$document-background-color: #ffffff;
$h1-color: #000000;

/** COLORS **/
$ui-color: #0F265C;
$ui-color-secondary: #55688D;
$ui-error: #F44242;
$ui-error-background: #FFF8F8;
$ui-error-border-dark: #FAC6C6;
$ui-error-border-light: #FFECEC;
$ui-color-dark: #1D1D1B;
$ui-color-active: #009CDE;
$ui-data-tile-color: #BCBCCB;
$ui-placeholder-color: #d0d0d0;
$ui-table-header: #ffffff;

/** MERD Color Palette **/
$color-black: #1D1D1B;
$color-white: #FFFFFF;

$color-primary-100: #F8FDFF; // blue
$color-primary-200: #EDF9FF;
$color-primary-300: #BFE4F5;
$color-primary-400: #81CEEF;
$color-primary-500: #3FB5E6;
$color-primary-600: #009CDE;

$color-secondary-100: #FBFCFF; // navy
$color-secondary-200: #F7F8FB;
$color-secondary-300: #E2E7EF;
$color-secondary-400: #96A3BD;
$color-secondary-500: #55688D;
$color-secondary-600: #0F265C;

$color-gray-100: #FBFBFB; // gray
$color-gray-200: #F6F6F6;
$color-gray-300: #EFEFEF;
$color-gray-400: #E0E0E0;
$color-gray-500: #A1A1A1;
$color-gray-600: #545454;

$color-success-100: #F4FFF7; // green
$color-success-200: #ECFBF0;
$color-success-300: #CCF2D7;
$color-success-400: #A2D1AF;
$color-success-500: #73BE89;
$color-success-600: #47B062;

$color-warning-100: #FFF6F2; // orange
$color-warning-200: #FFEFE8;
$color-warning-300: #F9D4C3;
$color-warning-400: #F4A786;
$color-warning-500: #EF7C4A;
$color-warning-600: #E9500E;

$color-danger-100: #FFF8F8; // red
$color-danger-200: #FFECEC;
$color-danger-300: #FAC6C6;
$color-danger-400: #ED7D7D;
$color-danger-500: #F24747;
$color-danger-600: #E21212;
/** End MERD color palette **/

/** Buttons **/
$button-color: #0F265C;
$button-color__hover: #ffa700;
$button-color__focused: #ffa700;
$button-color-grey: #C6C6C6;
$button-color-text-grey__disabled: #9A9A9A;
$button-color__disabled: #C6C6C6;
$button-color-background__disabled: #C6C6C6;

/** Inputs **/
$input-height: 48px;
$input-radio-height: 34px;
$input-min-width: 4rem;
$input-color-background: $color-secondary-200;
$input-color-background__disabled: $color-gray-200;
$input-color-background__error: $color-danger-100;
$input-color-text: #55688D;
$input-color-text__focused: $color-secondary-600;
$input-color-text__disabled: $color-gray-500;
$input-color-placeholder: $color-secondary-500;
$input-border: 1px solid $color-secondary-200;
$input-border__focused: $color-primary-500;
$input-border-radius: 4px;
$input-box-shadow: none;
$input-padding: 12px 8px;
$input-validation-span-size: 15px;
$input-text-font-size: 15px;
$input-label-font-size: 15px;
$input-label-color: $color-secondary-400;
$input-required-color: $color-danger-600;

/** Tags **/
$tag-color: $ui-color;
$tag-background-color: #A3A3E8;
$tag-background-color__hover: $ui-color;

/** Font SIZES **/
$font-size-rel-small: 0.9rem;
$font-size-rel-normal: 1rem;
$font-size-rel-large: 1.5rem;

/** Font COLORS **/
$input-font-color: #979797;
$input-font-color-selected: $ui-color;
$font-color-label: #2e3039;
$font-color-placeholder: #d0d0d0;
$font-color-sublabel: #202020;

/** Animations variables **/
$animation-length-blink: 100ms;
$animation-length-short: 300ms;
$animation-length-medium: 500ms;
$animation-length-long: 1000ms;
$animation-delay-short: 50ms;

/******* UIButton Component ********/
$button-size: 60px;

/******* AuthorizationCore Component ********/
$authorization-logo-width: 200px;
$authorization-logo-height: 56px;
$authorization-mobile-background-height: 12rem;
$authorization-window-width: 65rem;
$authorization-window-min-width: $break-medium;
$authorization-window-height: 100vh;

/******* Core Component ********/
$top-nav-height: 64px;
$side-bar-width: 600px;
$content-container-padding: 24px;
$content-container-padding-mobile: 24px;

/******* Navbar Component *******/
$navigation-bar-height: 64px;
$navigation-bar-background-color: $color-secondary-200;
$navigation-bar-icon-size: 4rem;
$navigation-link-color: $color-black;
$cart-icon-size: 2.3rem;
$account-icon-size: 2.3rem;
$navigation-icon-size: 48px;
$navigation-icon-background: #E2E7EF;
$navigation-icon-background-active:#BFE4F5;

/******* Footer Component *******/
$footer-height: 234px;

/******** Product Component *******/
$thumbnail-size: 12rem;
$product-name-font-size: 2rem;

/******** Searchbar Component ********/
$search-bar-min-width: 608px;
$searchbar-min-width: 40rem;
$searchbar-height: 48px;
$searchbar-max-height: 60rem;

/******** Drawer Component ********/
$hamburger-color: #000000;
$hamburger-size: 20px;
$drawer-width: 400px;
$drawer-width-desktop: 316px;
$drawer-background-size: calc($drawer-width / 3);
$drawer-border: #E2E7EF;

/******** Search Component ********/
$search-tile-height: 40px;

/******** UIExtensionPanel Component ********/
$ui-extension-panel-height: 7rem;
$ui-extension-panel-max-height: 15rem;
$ui-extension-panel-disabled-color: rgb(247, 247, 247);

/******** UIForm Component ********/
$ui-form-header-height: 4rem;
$ui-form-indicator-size: 3rem;
$ui-form-indicator-active: #979797;
$ui-form-thumbnail-size: 10rem;
$ui-form-footer-min-height: 15rem;

/******** UITable Component ********/
$row-max-height: 4rem;
$cell-padding: 2em 1em;
$sticky-column-color: #f2f2f2;
$header-color: #A3A6B4;
$header-background-color: #DFDFF7;
$table-searchbar-max-width: 20rem;
$sortable-icon-size: 5px;
$sortable-icon-color: #d0d0d0;

/********** Inputs *************/
$input-padding: 0.5rem;
$input-size-desktop: 1rem;
$input-size-handhelds: 1.1rem;
$input-background-color: #EBECF0;
$input-error-color: #FF6565;
$input-disabled-color: #83838e;
$ui-select-content-index: 10;
$ui-datepicker-content-index: 15;
$ui-datepicker-width: 25rem;

/********** UIRadiobutton ***********/
$radiobutton-color: $header-background-color;
$radiobutton-stroke: 2px;
$radiobutton-size: 20px;

/********** UI Toggle ************/
$toggle-width: 48px;
$toggle-height: 24px;
$toggle-switch-size: 16px;

/********** Cart drawer ************/
$cart-notification-size: 16px;
$order-notification-size: 6px;

/********** Table tags *************/
$table-tag-green: #CEFFD6;
$table-tag-yellow: #EAEDC7;
$table-tag-red: #FDB3A1;
