/* Global styles and imports */

@import 'style_reset';
@import 'style_config';
@import 'style_mixins';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '@angular/cdk/a11y-prebuilt.css';
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap');

/* ================ DOCUMENT ================ */
body.modal-opened {
  .tabs-header {
    z-index: unset !important;
  }
}
body.--prevent-scroll {
  .tabs-header {
    z-index: unset !important;
  }
}
html {
  max-width: $app-width;
  margin: 0 auto;
  font-size: $font-size-small;
  font-family: $font-family;
  @media screen and (min-width: $break-small) { font-size: $font-size-large; }
  color: #08080B;
}
small {
  font-size: 1rem;
  color: $ui-placeholder-color;
  padding: $small-padding 0;
  display: block;
}
textarea, input, button {
  font-family: $font-family;
}
code {
  font-family: 'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo, Consolas, Courier, monospace;
}
.order-input {
  -webkit-appearance: none;
  border-radius: 0;
}
#confetti-trigger {
  position: fixed;
  top: 100vh;
  z-index: 2000;
}

/* ================ Common animation classes ================ */
.fly-in {
    @include fly-in(right, $animation-length-short, 0ms);
}
.fade-in { @include fade-in($animation-length-short, 0ms); }
.fade-in-long { @include fade-in($animation-length-long, 0ms); }
.fade-out { @include fade-out(400ms, 0ms); }
.async-loader {
  width: 15px;
  height: 15px;
  background-image: url('/assets/loader/async-loader.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation: rotate 500ms linear infinite;
}
@keyframes rotate {
    from { transform: rotate(0); }
    to { transform: rotate(360deg); }
  }
.grow-component {
    @include grow-component();
}
.rotate {
    animation: rotate 500ms linear infinite;
}


/*  ================ Buttons ================ */
.bar-cancel-button {
  color: $ui-color;
  &[disabled="true"] {
    color: $button-color-text-grey__disabled;
    cursor: not-allowed;
  }
  @include bar-button();
}
.bar-accept-button {
  color: #FFFFFF;
  background-color: $ui-color;
  border-radius: 30px;
  &[disabled="true"] {
    background-color: $button-color-background__disabled;
    color: $button-color-text-grey__disabled;
    cursor: not-allowed;
  }
  @include bar-button();
}
.bar-outline-button {
  color: $ui-color;
  border-radius: 2rem;
  font-size: .75rem;
  padding: .6rem 1rem;
  border: 1px solid $ui-color;
  max-height: 3rem;
  transition: all .3s;
  &:hover { background-color: $button-color__hover; }
  @include bar-button();
}


/*  ================ Tags ================  */
.table-tag {
  border-radius: 4px;
  padding: 4px;
  text-align: center;
  white-space: nowrap;
  font-weight: 400 !important;
  font-size: 15px;
  margin-right: auto;
  margin-left: 10px;
  width: 105px;
  line-height: 24px;
}
.yellow-tag { color: #FFCF4E; border: 1px solid #FFCF4E; }
.green-tag { color: #1D1D1B; background-color: #ECFBF0;}
.red-tag { color: #1D1D1B; background-color: #FFEFE8; }
.blue-tag { color: #4242FA; border: 1px solid #4242FA;  }
.dark-blue-tag { color: #3535B4; border: 1px solid #3535B4;  }
.gray-tag { color: #202020; border: 1px solid #202020;  }


/* ================ Code, JSON editor ================ */
.json-editor {
  font-family: 'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo, Consolas, Courier, monospace;
  background-color: rgb(40, 52, 71);
  color: rgb(220, 229, 245);
  white-space: pre-wrap;
  overflow-x: auto;
  font-size: 12px;
  display: block;
  width: 100%;
  min-height: 200px;
  border-radius: 3px;
  padding: $input-padding;
}


/* ================ Modifiers ================ */
.--prevent-scroll { overflow: hidden !important; }
.--empty-grow { flex-grow: 2; }
.--link {
    color: $ui-color !important;
    cursor: pointer;
    &:not([disabled="true"]):hover { text-decoration: underline; }
    &[disabled="true"] { color: $input-color-text__disabled; }
}
.--flex-row-nowrap { display: flex; flex-flow: row nowrap; }
.--flex-row-wrap { display: flex; flex-flow: row wrap; align-items: center; }
.--flex-column { display: flex; flex-flow: column; }
.--display-block { display: block; }
.--semibold { font-weight: 600; }
.--mobile-only { display: none; @include respond-to (handhelds) { display: block; } }
.--desktop-only { @include respond-to(medium) { display: none; } }
.--flex-mobile-only { display: none; @include respond-to(medium) { display: flex; } }

.--error { display: block; margin: .5rem 0; color: #FF6565 !important; line-height: 1 !important; }


/* ================ Containers ================ */
.content-container {
    margin: 0 24px 16px 24px;

    @include respond-to(medium) {
      margin: 0 24px;
    }
}
.content-container-with-back {
  margin: 0 24px 16px 56px;

  @include respond-to(medium) {
    margin: 0 24px 16px 24px;
  }
}

.content-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 16px;
    height: 48px;
    h1 {
      color: $color-secondary-600;
      font-weight: 800;
      line-height: 34px;
    }
    ui-icon { margin-right: 12px; }
}

/* ================ Search tile ================ */

.photo-thumbnail {
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
}

/* ================ Forms, forms in dialogs ================ */
.dialog-form-container {
  display: flex;
  flex-shrink: 1;
  flex-grow: 2;
  flex-flow: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 3px 2px;
  h4 { color: #202020; font-weight: 600; margin-bottom: .5rem; }
  ie-input { min-width: 350px; margin-bottom: 1rem; }
  ie-multiselect { min-width: 350px; margin-bottom: 1rem; }
  .row-fields {
    display: flex;
    flex-flow: row nowrap;
    flex-shrink: 0;
    ie-input { flex-grow: 2; flex-basis: calc(50% - 1rem); min-width: unset; }
    ie-multiselect { flex-grow: 2; flex-basis: calc(50% - 1rem); min-width: unset; }
    ie-input:not(:last-of-type) { margin-right: 1rem; }
    ie-multiselect:not(:last-of-type) { margin-right: 1rem; }
  }
  .row-toggle-field {
    display: flex;
    flex-flow: column;
    margin-top: 1rem;
    label:first-child {
      font-size: .85rem;
      color: $input-label-color;
    }
    span:last-child { line-height: 1.5; }
  }
  @media screen and (max-width: 768px) {
    min-width: unset !important;
    ie-input { min-width: 100%; }
    ie-multiselect { min-width: 100%; }
    .row-fields { flex-flow: row wrap; }
  }
}
.dialog-form-buttons {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  z-index: 1;
  padding-top: 1rem;
}

.buttons-container {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    padding-bottom: 1.5rem;
    ie-button { margin: 1rem .5rem 0 .5rem; }
    @include respond-to(medium) { flex-flow: row wrap; }
}

.file-input {
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    cursor: pointer;
}

/* ================ Reports ================ */
.data-tile {
  margin: 1rem 0;
  height: 11.5rem;
  padding: 3.1rem 1.5rem;
  background: #FFF;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04);

  .big-label {
    font-size: 2.5rem;
    font-weight: 600 !important;
    color: $h1-color;
  }

  label {
    display: block;
    padding-bottom: 1rem;
    color: $ui-data-tile-color;
  }
}

google-chart {
    @include fade-in(1000ms, 200ms);
}
.inputs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: 0.9rem;
    @include respond-to(medium) {
        ie-auth-input, ie-datepicker, ie-select, ie-datepicker-extended, ie-multiselect { min-width: 100% !important; }
    }
    ie-auth-input, ie-select, ie-multiselect {
        min-width: 18rem; max-width: 18rem; padding: 1rem 2rem 1rem 0; &:last-child { padding-right: 0; }
    }
    ie-datepicker { padding: 1rem 2rem 1rem 0; min-width: 18rem; }
}


/* ================ Overlay ================ */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 997;
}

.overlay-dark {
    z-index: 1001;
    background: rgba(15, 38, 92, 0.7) none repeat scroll 0% 0%;
}
.overlay-clear { background-color: transparent; }

/* ================ Object card ================ */
.object-card {
    padding: 3rem;
    display: flex;
    flex-flow: row nowrap;
    ie-avatar { margin-right: 2rem; margin-bottom: 2rem; }
    .object-tags {
        margin: 0 2rem;
        max-width: 400px;
        @media screen and (min-width: 650px) and (max-width: 800px) {
            margin: 0 1rem;
        }
        @media screen and (min-width: 400px) and (max-width: 650px) {
            margin: 1rem 0 0 0;
            width: 100%;
        }
        @media screen and (max-width: 400px) {
            margin: 1rem 0 0 0;
            width: 100%;
        }
    }
    .object-details {
        margin-top: .5rem;
        table {
            tr {
                td { color: #202020; line-height: 1.5; }
                td:first-child {
                    min-width: 100px;
                    font-weight: 600;
                    padding-right: .65rem;
                }
                td:last-child {
                    min-width: 260px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        padding: 1rem;
        flex-flow: row wrap;
        .object-details {
            margin-top: 2rem;
            table { tr { td:first-child { min-width: 150px; }}}
            table { tr { td:last-child {
                max-width: 200px;
                min-width: 50px;
            } }}
        }
    }
    @media screen and (max-width: 400px) {
      .object-details {
        table { tr { td:first-child { min-width: 50px; } } }
      }
    }
}

/* ================ Onboarding ================ */
.onboarding-container, .onboarding-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  @include fade-in(500ms, 0ms);
}
.onboarding-container {
  z-index: 2001;
}
.onboarding-overlay {
  background-color: rgba(9, 30, 66, 0.5);
  z-index: 2000;
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar-track {
    background: $color-gray-100;
    border: 1px solid $color-gray-300;
}

::-webkit-scrollbar-thumb {
  background-color: $color-primary-300 !important;
  border-radius: 3px !important;
}
::-webkit-scrollbar-button:single-button {
  background-color: transparent;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  display: block;
  height: 6px;
  width: 6px;
}
::-webkit-scrollbar-button:horizontal:increment {
  background-image: url('https://e-urtica.pl/assets/scroll/right.svg');
  background-repeat: no-repeat;
  background-size: 4px 4px;
  background-position: center;
}
::-webkit-scrollbar-button:horizontal:decrement {
  background-image: url('https://e-urtica.pl/assets/scroll/left.svg');
  background-repeat: no-repeat;
  background-size: 4px 4px;
  background-position: center;
}

::-webkit-scrollbar-button:vertical:increment {
  background-image: url('https://e-urtica.pl/assets/scroll/down.svg');
  background-repeat: no-repeat;
  background-size: 4px 4px;
  background-position: center;
}
::-webkit-scrollbar-button:vertical:decrement {
  background-image: url('https://e-urtica.pl/assets/scroll/up.svg');
  background-repeat: no-repeat;
  background-size: 4px 4px;
  background-position: center;
}
body {
  scrollbar-color: $color-primary-300 !important;
  scrollbar-width: 6px !important;
}
