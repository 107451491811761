@import 'style_config';

@mixin flex-box {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
@mixin bar-button {
  line-height: 1;
  font-size: 1rem;
  padding: .9rem 1.8rem;
  @media screen and (max-width: 768px) {
    font-size: 13px;
    padding: .7rem 1.8rem;
  }
}

@mixin justify-content($justify) {
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
    -ms-flex-pack: $justify;
}

@mixin align-items($align) {
    -webkit-align-items: $align;
    -moz-align-items: $align;
    -ms-align-items: $align;
    align-items: $align;
}

@mixin full-window() {
    width: 100%;
    height: 100vh;
}

@mixin container-size($w, $h) {
    width: $w;
    height: $h;
}

@mixin grow-component {
    animation: grow-component 300ms cubic-bezier(0.175, 0.885, 0.32, 1.05) forwards;
}
@mixin shrink-component($delay) {
    animation: shrink-component 300ms cubic-bezier(0.175, 0.885, 0.32, 1.05) $delay forwards;
}

@mixin fly-in-component {
    transform-origin: top;
    animation: fly-in-bottom-to-top 300ms ease;
}

@mixin fly-in-top-to-bottom-light {
    transform-origin: top;
    animation: fly-in-top-to-bottom-light 300ms ease;
}
@mixin slide-in($duration) {
  transform-origin: top;
  animation: slide-in $duration ease forwards;
}
@mixin show-component {
    transform-origin: top;
    animation: show-component 300ms ease;
}

@mixin show-component-content {
    opacity: 0;
    animation: show-component-content 400ms ease 100ms forwards;
}

@mixin respond-to($media) {
    @if $media == handhelds {
        @media only screen and (max-width: $break-small) { @content; }
    }@else if $media == medium {
        @media only screen and (max-width: $break-medium) { @content; }
    }@else if $media == desktop {
        @media only screen and (min-width: $break-medium) { @content; }
    }
}

@mixin scaleX($duration, $delay) {
    transform-origin: center;
    animation-name: scale-x;
    animation-duration: $duration;
    animation-timing-function: linear;
    animation-delay: $delay;
}

@mixin scaleY($duration, $delay) {
  transform-origin: top;
  animation-name: scale-y;
  animation-duration: $duration;
  animation-timing-function: ease;
  animation-delay: $delay;
}

@mixin fly-in($direction, $duration, $delay) {
    will-change: transform;
    @if $direction == top {
        animation: fly-in-top-to-bottom $duration $delay forwards;
    } @else if $direction == right {
        animation: fly-in-left-to-right $duration $delay forwards;
    } @else if $direction == bottom {
        animation: fly-in-bottom-to-top $duration $delay forwards;
    } @else if $direction == left {
        animation: fly-in-right-to-left $duration $delay forwards;
    }
}

@mixin fly-out($direction, $duration, $delay) {
    @if $direction == bottom {
        will-change: transform;
        animation: fly-out-to-bottom $duration cubic-bezier(.47,.72,.3,.99) $delay forwards !important;
    }
}

@mixin scale($duration) {
    animation: scale $duration ease forwards;
}

@mixin fade-in($duration, $delay) {
    opacity: 0;
    animation: fade-in $duration ease $delay forwards;
}

@mixin fade-out($duration, $delay) {
    opacity: 1;
    animation: fade-out $duration ease $delay forwards !important;
}

/************** Animation KEYFRAMES **************/
@keyframes fly-in-left-to-right {
    0% { transform: translateX(-7%); }
    100% { transform: none; }
}

@keyframes fly-in-top-to-bottom {
    0% { transform: translateY(-200%); }
    100% { transform: none; }
}
@keyframes fly-in-top-to-bottom-light {
  0% { transform: translateY(-10%); opacity: 0; }
  100% { transform: none; opacity: 1; }
}

@keyframes fly-in-bottom-to-top {
    0% { transform: translateY(50%); }
    100% { transform: none; }
}

@keyframes fly-in-right-to-left {
    0% { transform: translateX(10%); }
    100% { transform: none; }
}

@keyframes fly-out-to-bottom {
    0% { opacity: 1; transform: none; }
    80% { opacity: 1; }
    100% { opacity: 0; transform: translateY(100%); }
}

@keyframes scale-x {
    0% { transform: scaleX(0); }
    100% { transform: scaleX(1); }
}

@keyframes scale-y {
  0% { transform: scaleY(0); }
  100% { transform: scaleY(1); }
}

@keyframes scale {
    0% { transform: scale(0); }
    100% { transform: scale(1); }
}

@keyframes slide-in {
  from { transform: translateY(20%); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fade-out {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes sheen {
    5% {
      opacity: .5;
    }
    100% {
      transform: scale(3);
      opacity: 0;
    }
}

@keyframes scale-fade-out {
    0% { transform: scale(0); opacity: 0; }
    50% { transform: scale(1.25); opacity: .7; }
    100% { transform: scale(2.5); opacity: 0; }
}

@keyframes show-component {
    0% { transform: scaleY(0); }
    100% { transform: scaleY(1); }
}

@keyframes show-component-content {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes grow-component {
    0% { transform: scale(.8); }
    100% { transform: scale(1); }
}
@keyframes shrink-component {
  0% { transform: scale(1); opacity: 1; }
  50% { opacity: 0; }
  100% { transform: scale(0); opacity: 0; }
}
@keyframes show-up-mobile {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
